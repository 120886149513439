<template>
  <div class="admin-controls" v-if="userIsStaffState">
    <div class="status meta-text">
      <strong>Status: </strong>
      <span>{{ source.status_display }}</span>
    </div>
    <div class="status meta-text">
      <strong>Person ID: </strong>
      <span>{{ personId }}</span>
    </div>
    <div v-if="externalPersonId" class="status meta-text">
      <strong>External ID: </strong>
      <span>{{ externalPersonId }}</span>
    </div>
    <div class="buttons">
      <mcr-button @click="openEditModal">Edit</mcr-button>
      <mcr-button @click="openAddRelativeModal">Add Relative</mcr-button>
      <mcr-button v-if="canPublish" @click="publish" :loading="publishLoading">Publish</mcr-button>
      <mcr-button :href="adminEditLink" target="_blank" class="transparent">Open in admin</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {publishStatuses} from '@common/utils/consts.source';
import {getPersonAdminUrl} from '@common/utils/utils.admin';
import {mapGetters} from 'vuex';

export default {
  props: {
    personId: [String, Number],
    externalPersonId: [String],
    source: Object,
  },
  data() {
    return {
      publishLoading: false,
    };
  },
  computed: {
    ...mapGetters(['userIsStaffState']),
    canPublish() {
      return this.source.status !== publishStatuses.ACTIVE;
    },
    sourceId() {
      return this.source.id;
    },
    adminEditLink() {
      return getPersonAdminUrl(this.personId);
    },
  },
  methods: {
    openEditModal() {
      this.$modal.show(`person-edit-modal-${this.sourceId}`);
    },
    openAddRelativeModal() {
      this.$modal.show(`add-relative-modal-${this.sourceId}`);
    },
    publish() {
      this.publishLoading = true;
      this.$store
        .dispatch('publishMentionsAction', {
          personId: this.personId,
          sourceId: this.sourceId,
        })
        .then(response => {
          this.$store.commit('activatePersonMentionsState', this.sourceId);
          this.$toasted.success('Published Successfully!');
          this.publishLoading = false;
        });
    },
  },
  components: {McrButton},
  name: 'AdminControls',
};
</script>

<style lang="scss" scoped>
.admin-controls .meta-text {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  strong {
    margin-right: 5px;
  }
}
.admin-controls .buttons {
  display: flex;
  justify-content: flex-end;

  .mcr-button {
    background-color: $admin-color;
    &:not(:last-child) {
      margin-right: 20px;
    }

    &.transparent {
      background-color: $admin-color-light;
      border: 1px solid $admin-color;
      color: $admin-color;
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    flex-direction: column;
    .mcr-button {
      margin-bottom: 20px;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
</style>
